<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="角色管理"
      class="px-5 py-3 mb-5"
    >
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="success"
                @click="addRoleBtn"
              >
                <v-icon left>
                  fa fa-plus
                </v-icon>
                添加角色
              </v-btn>
            </v-col>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                    width="350"
                  >
                    角色名称
                  </th>
                  <th
                    class="text-left"
                  >
                    角色ID
                  </th>
                  <th
                    class="text-right"
                    width="200"
                  >
                    操作
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(role, index) in roleList"
                  :key="index + role"
                >
                  <td>{{ role.name }}</td>
                  <td>{{ role.id }}</td>
                  <td>
                    <v-btn
                      depressed
                      class="mx-2"
                      small
                      color="success"
                      @click="editRoleBtn(index)"
                    >
                      <v-icon
                        small
                        left
                      >
                        mdi-pencil
                      </v-icon>
                      编辑
                    </v-btn>
                    <v-btn
                      depressed
                      class="mx-2"
                      small
                      color="error"
                      @click="delRole(index)"
                    >
                      <v-icon
                        small
                        left
                      >
                        fa fa-trash-o
                      </v-icon>
                      删除
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      v-model="addRoleDialog"
      fullscreen
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>设置角色权限</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="addRoleDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-row>
              <v-col
                cols="2"
              >
                <v-text-field
                  v-model="roleName"
                  label="角色名称"
                  outlined
                  dense
                  clearable
                />
              </v-col>
              <v-col
                cols="2"
              >
                <v-text-field
                  v-model="roleCode"
                  label="角色代码"
                  outlined
                  dense
                  clearable
                />
              </v-col>
              <v-col
                cols="2"
              >
                <v-text-field
                  v-model="roleWeight"
                  label="排序"
                  outlined
                  dense
                  clearable
                />
              </v-col>
              <v-col
                cols="2"
              >
                <v-text-field
                  v-model="roleExplain"
                  label="说明"
                  outlined
                  dense
                  clearable
                />
              </v-col>
              <v-col
                cols="2"
              >
                <v-btn
                  v-if="addBtn"
                  color="success"
                  @click="addRole"
                >
                  保存角色
                </v-btn>
                <v-btn
                  v-if="!addBtn"
                  color="success"
                  @click="editRole"
                >
                  修改角色
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-checkbox
                  v-model="checkbox1"
                  label="首页"
                  disabled
                />
                <v-divider />
              </v-col>
              <v-col
                v-for="index in 10"
                :key="index"
                cols="1"
              >
                <v-checkbox
                  v-model="checkbox1"
                  label="首页"
                  disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-checkbox
                  v-model="checkbox1"
                  label="首页"
                  disabled
                />
                <v-divider />
              </v-col>
              <v-col
                v-for="index in 10"
                :key="index"
                cols="1"
              >
                <v-checkbox
                  v-model="checkbox1"
                  label="首页"
                  disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-checkbox
                  v-model="checkbox1"
                  label="首页"
                  disabled
                />
                <v-divider />
              </v-col>
              <v-col
                v-for="index in 10"
                :key="index"
                cols="1"
              >
                <v-checkbox
                  v-model="checkbox1"
                  label="首页"
                  disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                color="success"
                disabled
              >
                保存角色
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import http from '../../api'
  export default {
    data () {
      return {
        addRoleDialog: false,
        checkbox1: true,
        roleName: '',
        roleCode: 'ROLE_',
        roleWeight: '',
        roleExplain: '',
        roleList: [],
        roleId: '',
        addBtn: true,
        headers: [
          {
            text: '角色ID',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: '角色名称',
            value: 'nickName',
            sortable: false,
          },
          {
            text: '操作',
            value: 'mobile',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },
    created () {
      this.getRole()
    },
    methods: {
      addRoleBtn () {
        this.addBtn = true
        this.addRoleDialog = true
        this.roleName = ''
        this.roleCode = 'ROLE_'
        this.roleExplain = ''
        this.roleWeight = 0
      },
      addRole () {
        this.addBtn = true
        http.groups.addGroups({
          name: this.roleName,
          value: this.roleCode,
          weight: 0,
          note: this.roleExplain,
          parentId: null,
          type: 'role',
        })
          .then(res => {
            this.$swal({
              title: '保存成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getRole()
            this.addRoleDialog = false
          })
      },
      getRole () {
        http.groups.getGroups()
          .then(res => {
            this.roleList = res.data.data
          })
      },
      delRole (index) {
        this.$swal({
          title: '你确定要删除"' + this.roleList[index].name + '"吗？',
          text: '删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            http.groups.delGroups(this.roleList[index].id)
              .then(res => {
                this.roleList.splice(index, 1)
              })
              .catch(err => {
                this.$swal({
                  title: '删除失败',
                  icon: 'error',
                  text: '原因:' + err.response.data.msg,
                  confirmButtonText: '知道了',
                })
              })
          }
        })
      },
      editRoleBtn (index) {
        this.addBtn = false
        this.addRoleDialog = true
        this.roleId = this.roleList[index].id
        this.roleName = this.roleList[index].name
        this.roleCode = this.roleList[index].value
        this.roleExplain = this.roleList[index].note
        this.roleWeight = this.roleList[index].weight
      },
      editRole () {
        http.groups.editGroups(this.roleId, {
          name: this.roleName,
          value: this.roleCode,
          note: this.roleExplain,
          weight: 0,
          parentId: null,
          type: 'role',
        })
          .then(res => {
            this.addRoleDialog = false
            this.getRole()
            this.$swal({
              title: '修改成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
</style>
